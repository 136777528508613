import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Html5QrcodeScanner } from "html5-qrcode";
import { FaHome, FaQrcode, FaBook } from 'react-icons/fa';
import { MdSignalWifiConnectedNoInternet2 } from 'react-icons/md';
import { GiBookshelf } from 'react-icons/gi';
import { isMobile } from "react-device-detect";

import './App.css';

import AboutUs from './views/AboutUs';
import CamouflageStories from './views/CamouflageStories';
import CamouflageStoryDetails from './views/CamouflageStoryDetails';
import Search from './views/Search';
import ArtifactDetails from './views/ArtifactDetails';

const VIEW = {
  SEARCH: 'view-search',
  CAMOUFLAGE_STORIES: 'camouflage-stories',
  CAMOUFLAGE_STORY_DETAILS: 'camouflage-story-details',
  ABOUT_US: 'view-about-us',
  ARTIFACT_DETAILS: 'view-item-details',
  QR_CODE_SCAN: 'qr-code-scan'
};

let html5QrcodeScanner;

class App extends React.Component {

  state = {
    view: VIEW.ABOUT_US,
    backView: VIEW.ABOUT_US,
    selectedArtifact: undefined,
    selectedStory: undefined,
    statistics: [],
    artifacts: [],
    categories: [],
    stories: [],
    warningMessage: undefined
  };

  componentDidMount = () => {
    window.history.pushState({}, '');
    window.onpopstate = this.onPopState;

    fetch(`${process.env.REACT_APP_BE_URL}/statistics`)
      .then(response => response.json())
      .then(statistics => {
        this.setState({ statistics: statistics });
      });

    fetch(`${process.env.REACT_APP_BE_URL}/categories`)
      .then(response => response.json())
      .then(categories => {
        this.setState({ categories: categories });
      });

    fetch(`${process.env.REACT_APP_BE_URL}/stories`)
      .then(response => response.json())
      .then(stories => {
        this.setState({ stories: stories });
      });


    let localHash = window.localStorage.getItem('hash') || '';
    fetch(`${process.env.REACT_APP_BE_URL}/artifactshash`)
      .then(response => response.json())
      .then(data => {
        let dataHash = data.hash;
        if (dataHash === localHash) {
          this.loadLocalData();
        } else {
          fetch(`${process.env.REACT_APP_BE_URL}/artifacts`)
            .then(response => response.json())
            .then(artifacts => {
              this.setState({ artifacts: artifacts });
              window.localStorage.setItem('artifacts', JSON.stringify(artifacts));
              window.localStorage.setItem('hash', dataHash);
            })
            .catch(() => {
              this.loadLocalData();

            });
        }
      }).catch(() => {
        this.loadLocalData();
        this.displayWarning("Greška u komunikaciji sa serverom.");
      });
  }

  loadQrScanner = () => {
    if (html5QrcodeScanner) {
      return;
    }

    html5QrcodeScanner = new Html5QrcodeScanner("qr-code-reader", { fps: 10, qrbox: { width: 250, height: 250 } }, /* verbose= */ false);
    if (document.querySelector("#qr-code-reader").innerHTML === '') {
      html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
    }
  }

  displayWarning = (message) => {
    this.setState({ warningMessage: message });
    setTimeout(() => {
      this.setState({ warningMessage: undefined });
    }, 1500);
  }

  loadLocalData = () => {
    this.setState({ artifacts: JSON.parse(window.localStorage.getItem('artifacts')) || [] });
  }

  changeView = (view, backView, artifact, story) => {
    if (view === this.state.view) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.setState({
        view: view,
        backView: backView,
        selectedArtifact: artifact,
        selectedStory: story
      });
      window.scrollTo({ top: 0, behavior: "instant" })
    }

    if (view === VIEW.QR_CODE_SCAN) {
      this.loadQrScanner();
    }
  }

  onPopState = (event) => {
    this.changeView(this.state.backView, VIEW.ABOUT_US);
  }

  displayArtifactDetails = (id) => {
    let artifact = this.state.artifacts.filter(i => i.id === id)[0] || {};
    window.history.pushState({}, '');
    this.changeView(VIEW.ARTIFACT_DETAILS, this.state.view, artifact, undefined);
  }

  displayStoryDetails = (id) => {
    let story = this.state.stories.filter(i => i.id === id)[0] || {};
    window.history.pushState({}, '');
    this.changeView(VIEW.CAMOUFLAGE_STORY_DETAILS, this.state.view, undefined, story);
  }

  onScanSuccess = (decodedText, decodedResult) => {
    if (this.state.view !== VIEW.QR_CODE_SCAN) {
      return;
    }

    this.displayArtifactDetails(decodedText);
  }

  render() {
    return (
      <>
        {this.state.warningMessage ? <div className='alert-message w-100 bg-danger text-light' variant="warning">
          <MdSignalWifiConnectedNoInternet2 className='mx-2' /> Greška u komunikaciji sa serverom.
        </div> : undefined}

        <Navbar bg="dark" variant="dark">
          <Container>
            <Navbar.Brand><img className='mx-3' src="https://pas-lepoglava.hr/images/site/logo/Logo-transparent-64.png" alt='P.A.S. klub Lepoglava Logo'></img> P.A.S. klub Lepoglava</Navbar.Brand>
            {!isMobile ? <Nav className="me-auto">
              <Nav.Link onClick={() => this.changeView(VIEW.ABOUT_US)} active={this.state.view === VIEW.ABOUT_US}>Početna</Nav.Link>
              <Nav.Link onClick={() => this.changeView(VIEW.CAMOUFLAGE_STORIES)} active={this.state.view === VIEW.CAMOUFLAGE_STORIES}>Maskirne priče</Nav.Link>
              <Nav.Link onClick={() => this.changeView(VIEW.SEARCH)} active={this.state.view === VIEW.SEARCH}>Pregled</Nav.Link>
            </Nav> : undefined}
          </Container>
        </Navbar>

        {this.state.view === VIEW.ABOUT_US ? <AboutUs statistics={this.state.statistics} /> : undefined}
        
        <Container>
          {this.state.view === VIEW.CAMOUFLAGE_STORIES ? <CamouflageStories stories={this.state.stories || []} displayStoryDetails={this.displayStoryDetails} /> : undefined}

          {this.state.view === VIEW.CAMOUFLAGE_STORY_DETAILS && this.state.selectedStory ? <CamouflageStoryDetails story={this.state.selectedStory || {}} /> : undefined}

          <div id="qr-code-reader" className='mt-3' style={{ display: this.state.view === VIEW.QR_CODE_SCAN ? 'block' : 'none' }}></div>

          {this.state.view === VIEW.SEARCH ? <Search artifacts={this.state.artifacts || []} categories={this.state.categories || []} displayArtifactDetails={this.displayArtifactDetails} /> : undefined}

          {this.state.view === VIEW.ARTIFACT_DETAILS && this.state.selectedArtifact ? <ArtifactDetails artifact={this.state.selectedArtifact || {}} changeView={this.changeView} /> : undefined}
          
        </Container>


        {isMobile ?
          <>
            <div>
              &nbsp;
            </div>
            <div className='app-menu'>
              <ul className='menu-buttons'>
                <li className={`menu-button ${this.state.view === VIEW.ABOUT_US ? 'active-button' : ''}`} onClick={() => this.changeView(VIEW.ABOUT_US)}>
                  <FaHome />
                  <p>Početna</p>
                </li>
                <li className={`menu-button ${this.state.view === VIEW.CAMOUFLAGE_STORIES ? 'active-button' : ''}`} onClick={() => this.changeView(VIEW.CAMOUFLAGE_STORIES)}>
                  <FaBook />
                  <p>Maskirne priče</p>
                </li>
                <li className={`menu-button ${this.state.view === VIEW.SEARCH ? 'active-button' : ''}`} onClick={() => this.changeView(VIEW.SEARCH)}>
                  <GiBookshelf /><p>Pregled</p>
                </li>
                <li className={`menu-button ${this.state.view === VIEW.QR_CODE_SCAN ? 'active-button' : ''}`} onClick={() => this.changeView(VIEW.QR_CODE_SCAN)}>
                  <FaQrcode /><p>QR Code</p>
                </li>
              </ul>
            </div>
          </> : undefined}
      </>
    )
  }

}

export default App;
