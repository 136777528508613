import { Badge, Card, Col, Row } from 'react-bootstrap';
import { createHumanReadableDate } from '../utils.ts';

function CamouflageStories(props) {

  return (
    <>
      <h1 className="my-5">Maskirne priče</h1>

      <Row xs={1} md={2} lg={3} className="mb-5">
        {props.stories.map(story => (
          <Col key={story.id} className="my-2" onClick={() => props.displayStoryDetails(story.id)}>
            <Card>
              <Card.Img variant="top" src={story.image || ''} />
              <Card.Body>
                <Card.Title>{story.title}</Card.Title>
                <Badge className='mb-3' bg="secondary">{createHumanReadableDate(story.datePublished)}</Badge>
                <Card.Text>{story.listingDescription}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )

}

export default CamouflageStories;
