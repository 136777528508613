import React from 'react';
import { Accordion, Badge, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { isMobile } from "react-device-detect";

const DEFAULT_IMAGE_PLACEHOLDER = 'https://dummyimage.com/320x180/bbb/777';
const PAGE_SIZE = 10;

class Search extends React.Component {

    state = {
        searchTerm: '',
        displayPage: 0,
        categories: this.props.categories
    }

    filterArtifacts = () => {
        let artifacts = this.props.artifacts.slice();
        
        let selectedCategories = this.state.categories.filter(c => c.selected).map(c => c.id);
        if (selectedCategories.length > 0) {
            artifacts = artifacts.filter(a => selectedCategories.includes(a.categoryId));
        }

        if (this.state.searchTerm) {
            artifacts = artifacts.filter(i =>
                i.id === this.state.searchTerm ||
                i.title.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
                i.description.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            );
        }

        return artifacts;
    }

    updateValue = (e) => {
        this.setState({ searchTerm: e.target.value });
    }

    nextPage = () => {
        this.setState({ displayPage: this.state.displayPage + PAGE_SIZE });
    }

    selectCategory = (title) => {
        let categories = this.state.categories;
        categories.filter(c => c.title === title).forEach(c => c.selected = !c.selected);
        this.setState({categories: categories});
    }

    render() {
        let filteredArtifacts = this.filterArtifacts() || [];
        
        return <>
            <Accordion className='mt-2' defaultActiveKey={''}>
                <Accordion.Item id="search-accordion">
                    <Accordion.Header><FaSearch /> &nbsp; Filter izložbenih primjeraka</Accordion.Header>
                    <Accordion.Body>
                        <Row className='mb-3'>
                            <Form.Control type="text" placeholder='Upišite traženi pojam' onChange={(e) => this.updateValue(e)} value={this.state.searchTerm} />
                        </Row>
                        
                        <Row xs={2} md={6}>
                            {this.state.categories.map(category => (
                                <Button key={category.id} variant='link'>
                                    <Card onClick={() => this.selectCategory(category.title)}>
                                        <Card.Img variant="top" src={category.image || 'https://placehold.co/100'} />

                                        <Form.Check type='checkbox' checked={category.selected} style={{position: 'absolute', top: '5px', left: '5px'}} onChange={() => {}}/>
                                        <Badge style={{position: 'absolute', top: '5px', right: '5px'}} bg="dark">{category.count}</Badge>
                                        
                                        <p className='h5 my-1 bg-light text-dark text-center border-radius text-truncate' style={{position: 'absolute', bottom: 0, left: '5px', width: 'calc(100% - 10px)', opacity: '0.7'}}>
                                            {category.title}
                                        </p>
                                    </Card>
                                </Button>
                            ))}
                        </Row>

                        { isMobile ? <Button className='w-100' variant='outline-secondary' onClick={() => { window.scrollTo({ top: 0, behavior: 'instant' }); document.querySelector("#search-accordion > h2.accordion-header > button.accordion-button").click();}}>Zatvori</Button> : undefined }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            
            <hr />
            <Row xs={1} md={2} lg={4}>
                {filteredArtifacts.slice(0, this.state.displayPage + PAGE_SIZE).map(artifact => (
                    <Col key={artifact.id} className="mb-3 mb-md-4">
                        <Card onClick={() => this.props.displayArtifactDetails(artifact.id)}>
                            <Card.Img variant="top" src={artifact.image || DEFAULT_IMAGE_PLACEHOLDER} alt={artifact.title} />
                            <Card.Body>
                                <Card.Title>{artifact.title || ''}</Card.Title>
                                <Card.Text  style={{ maxHeight: '6em', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {(artifact.description || '').replace(/(<([^>]+)>)/ig, '').substring(0,150)}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <div className='mb-5'>
                {this.state.displayPage + PAGE_SIZE <= filteredArtifacts.length ?
                    <Button className='mt-3 mb-5 w-100' onClick={() => this.nextPage()}>Učitaj više</Button> : undefined}
            </div>
        </>;
    }
}

export default Search;