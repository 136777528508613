import { Badge, Card, Col, Row } from 'react-bootstrap';
import { createHumanReadableDate } from '../utils.ts';

function CamouflageStoryDetails(props) {

  return (
    <>
      <h1 className="my-5">{props.story.title}</h1>

      <Row className="mb-5">
        <Col className="my-2">
            <Card>
              <div className='mt-2 col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
              <Card.Img variant="top" src={props.story.image || ''} />
              </div>
              
              <Card.Body>
                <Badge className='mb-4' bg="secondary">{createHumanReadableDate(props.story.datePublished)}</Badge>
                <Card.Text dangerouslySetInnerHTML={{ __html: props.story.text || '' }}></Card.Text>
              </Card.Body>
            </Card>
          </Col>
      </Row>
    </>
  )

}

export default CamouflageStoryDetails;
