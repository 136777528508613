import { Figure, Container } from 'react-bootstrap';
import './AboutUs.css';

import Partners from '../containers/Partners';
import Statistics from '../containers/Statistics';

import helmetImage from '../images/helmet.jpg';

const generateFacebookUrl = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'https://www.facebook.com/P.A.S.klubLepoglava';
    }

    if (/android/i.test(userAgent)) {
        return 'fb://facewebmodal/f?href=https://www.facebook.com/P.A.S.klubLepoglava';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'fb://page/P.A.S.klubLepoglava';
    }
    return;
};

function AboutUs(props) {

    return (
        <>
            <div className="p-5 text-center" style={{ backgroundImage: `url("${helmetImage}")`, backgroundSize: 'contain' }} >
                <div className="rounded-2" style={{ backgroundColor: 'rgba(0, 0, 0, 0.65)' }}>
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="text-white pt-3">
                            <h1 className="mb-3">Izložbeni postav militarija</h1>
                            <h4 className="mb-3">P.A.S. klub Lepoglava</h4>
                        </div>
                    </div>
                </div>
            </div>
            <Figure.Caption style={{ marginTop: '-20px', float: 'right' }}>
                Photo by <a href="https://unsplash.com/@othentikisra?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">israel palacio</a> on <a href="https://unsplash.com/s/photos/military?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
            </Figure.Caption>

            <section className="bg-light" >
                <Container className='py-5'>
                    <p>Izložba vojnih oznaka, odora, fotografija i ostale vojne opreme pri čemu je poseban naglasak stavljen je na obilježavanje 30. godišnjice osnivanja 7. gardijske brigade PUMA. Izložbe u suradnji sa braniteljskim udrugama imaju edukativnu notu koja posjetiteljima približava važnije datume bliže povijesti, a posebice postrojbi HV koje su sudjelovale u Domovinskom ratu.</p>
                </Container>
            </section>

            <Partners />

            <Statistics statistics={props.statistics} />

            <footer className="w-100 py-4 flex-shrink-0 bg-dark">
                <div className="container py-4">
                    <div className="row by-4 bx-5">
                        <div className="col-lg-4 col-md-6 text-white">
                            <h5 className="h2">P.A.S. klub Lepoglava</h5>
                            <p className="small">je nacionalni klub koji potiče, promiče i skrbi o razvoju paintball-a, airsoft-a i survival-a, a osnovan je 2012. godine.</p>
                            <p className="smallmb-0">&copy; Sva prava pridržana. <a className="text-primary" href="https://pas-lepoglava.hr" target='_blank' rel="noreferrer">pas-lepoglava.hr</a></p>
                        </div>

                        <div className="col-lg-2 col-md-6">
                            <h5 className="text-white mb-3">Poveznice</h5>
                            <ul className="list-unstyled text-muted">
                                <li><a href='https://pas-lepoglava.hr/' target='_blank' rel="noreferrer">P.A.S. klub Lepoglava</a></li>
                                <li><a href={generateFacebookUrl()} >P.A.S. klub Lepoglava - Facebook</a></li>
                                <li><a href='https://www.lepoglava.hr/' target='_blank' rel="noreferrer">Grad Lepoglava</a></li>
                                <li><a href='https://www.varazdinska-zupanija.hr/' target='_blank' rel="noreferrer">Varaždinska županija</a></li>

                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6 text-white">
                            <h5 className="mb-4">Lokacija</h5>
                            <p className='mb-1'>Službene prostorije P.A.S. kluba Lepoglava</p>
                            <p className='mb-1'><a href='https://goo.gl/maps/iKtzXZtBxwTu9n7q7' target='_blank' rel="noreferrer">Trg 1. hrvatskog sveučilišta 7 [otvori mapu]</a></p>
                            <p>42250 Lepoglava</p>
                            <p><i>* Ulaz kroz park kod crkve blažene Djevice Marije u Lepoglavi</i></p>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )

}

export default AboutUs;
