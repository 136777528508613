import { Col, Container, Card, Row } from 'react-bootstrap';

const objectHash = require('object-hash');

function Statistics(props) {

    return (
        <section className='bg-light'>
            <Container className='py-5'>
                <h2 className='mb-4'>Militarije u brojkama</h2>

                <Row xs={1} md={2} lg={3}>

                    {props.statistics.map(number => (
                        <Col className='mb-3' key={objectHash.MD5(number)}>
                            <Card>
                                <Card.Body>
                                    <Card.Title className='text-center display-1'>{number.value}</Card.Title>
                                    <hr className='text-secondary mx-auto' style={{ width: '25%' }} />
                                    <Card.Text className='text-center lead'>{number.name}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>

    )

}

export default Statistics;
