import { Container, Col, Row, Card } from 'react-bootstrap';
import lepoglavaLogo from '../images/lepoglava.jpeg';
import vzzLogo from '../images/vzz.jpeg';
import pasVizual from '../images/pas-vizual.jpg';

const objectHash = require('object-hash');

const partnerList = [
    {
        name: 'Grad Lepoglava',
        image: lepoglavaLogo,
        url: 'https://www.lepoglava.hr/'
    },
    {
        name: 'Varaždinska županija',
        image: vzzLogo,
        url: 'https://www.varazdinska-zupanija.hr/'
    },
    {
        name: 'P.A.S. klub Lepoglava',
        image: pasVizual,
        url: 'https://pas-lepoglava.hr/'
    }
]

function Partners() {

    return (
        <section className='bg-light'>
            <Container className='pb-5'>
                <h2 className='py-4'>Partneri</h2>

                <Row xs={1} md={2} lg={3}>
                    {partnerList.map(partner => (
                        <Col className='d-flex align-items-center mb-3' key={objectHash.MD5(partner)}>
                            <Card className='w-100 align-items-center'>
                                <Card.Img variant="top" src={partner.image} style={{height: '15rem', width: '15rem', margin: '1rem 0'}} />
                                
                                <Card.Body>
                                    <Card.Title as='h3'>{partner.name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>

    )

}

export default Partners;
