import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import { isMobile } from "react-device-detect";
import { ReactImageTurntable } from "react-image-turntable";
import { MdOutline360 } from 'react-icons/md';

import './ArtifactDetails.css';

const DEFAULT_IMAGE_PLACEHOLDER = 'https://dummyimage.com/1280x720/bbb/bbb';

class ArtifactDetails extends React.Component {

  state = {
    images: [],
    display360: false
  }

  componentDidMount = () => {
    if (this.props.artifact.view360Id) {
      fetch(`${process.env.REACT_APP_BE_URL}/360view/${this.props.artifact.view360Id}`).then(response => response.json()).then(data => {
        let images = [];
        for (let i = 1; i<= data.amountOfImages; i++) {
            let image = `${data.folderUrl}image-${i}-${isMobile ? 'm' : 'd'}.${data.imageExtension || 'png'}`;
            images.push(image);
        }
        this.setState({images: images, display360: true});
      });
    }
  }

  render (){
    return (
      <Card className='mt-2 mb-5'>
        <div className='mt-2 col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
          { this.state.display360 ? <div className='rotation-icon'><MdOutline360 size={60} color='#fff'/></div> : <img className='w-100' src={this.props.artifact.image || DEFAULT_IMAGE_PLACEHOLDER} alt={this.props.artifact.title}/> }
          
          <ReactImageTurntable images={this.state.images} />
        </div>
        
        <Card.Body>
          <Card.Title>{this.props.artifact.title || 'Nepostojeći izložbeni primjerak, molimo pokušajte ponovno.'}</Card.Title>
          {this.props.artifact.productionTime || this.props.artifact.timeInService || this.props.artifact.funFact || (this.props.artifact.donor && this.props.artifact.donor.name) ?
            <Alert variant='secondary' className='mt-3 pt-4'>
              {this.props.artifact.productionTime ? <p><b>Vrijeme proizvodnje</b>: {this.props.artifact.productionTime}</p> : undefined}
              {this.props.artifact.timeInService ? <p><b>Vrijeme upotrebe</b>: {this.props.artifact.timeInService}</p> : undefined}
              {this.props.artifact.funFact ? <p><b>Zanimljivost</b>: {this.props.artifact.funFact}</p> : undefined}
              {this.props.artifact.donor && this.props.artifact.donor.name ? <p><b>Donirao</b>: {this.props.artifact.donor.name} {this.props.artifact.donor.date ? `[${this.props.artifact.donor.date}]` : ''}</p> : undefined}
            </Alert> : undefined}
          
          <Card.Text dangerouslySetInnerHTML={{ __html: this.props.artifact.description || '' }}>
          </Card.Text>
        </Card.Body>
        
      </Card>
    )
  }

}

export default ArtifactDetails;
